<template>
  <Dialog
    :header="$t('templates.addNewTemplate')"
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '50vw' }"
  >
    <div class="grid align-items-center">
      <div class="col-12 md:col-2 lg:col-2">
        <label for="name">{{ $t("templates.name") }} *:</label>
      </div>
      <div class="col-12 md:col-7 lg:col-6 pb-2">
        <div class="field p-fluid m-0">
          <InputText
            id="name"
            type="text"
            :class="{ 'p-invalid': v$.template.name.$invalid && submitted }"
            v-model="v$.template.name.$model"
            aria-describedby="name-error"
          />
          <!-- <small
            v-if="
              (v$.template.name.$invalid && submitted) ||
              v$.template.name.$pending.$response
            "
            class="p-error"
            >{{
              v$.template.name.required.$message.replace(
                "[value]",
                $t("templates.name")
              )
            }}</small
          > -->
        </div>
      </div>
      <ul
        v-if="v$.template.name.$error && submitted"
        class="p-error text-xs col-12 md:col-10 col-offset-0 md:col-offset-2 pt-0 mt-0 pl-5 mb-3"
      >
        <li v-for="(error, index) of v$.template.name.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("templates.name")) }}
        </li>
      </ul>
    </div>
    <div class="grid align-items-center">
      <div class="col-12 md:col-2 lg:col-2">
        <label for="type">{{ $t("templates.type") }} *:</label>
      </div>
      <div class="col-12 md:col-7 lg:col-6 pb-2">
        <div class="field p-fluid m-0">
          <!-- <InputText
            id="type"
            type="text"
            :class="{ 'p-invalid': v$.template.type.$invalid && submitted }"
            v-model="v$.template.type.$model"
            aria-describedby="type-error"
          /> -->

          <Dropdown
            :class="{ 'p-invalid': v$.template.type.$invalid && submitted }"
            v-model="v$.template.type.$model"
            :options="typeOptions"
            optionLabel="name"
            optionValue="type"
          />
          <!-- <small
            v-if="
              (v$.template.type.$invalid && submitted) ||
              v$.template.type.$pending.$response
            "
            class="p-error"
            >{{
              v$.template.type.required.$message.replace(
                "[value]",
                $t("templates.type")
              )
            }}</small
          > -->
        </div>
      </div>
      <ul
        v-if="v$.template.type.$error && submitted"
        class="p-error text-xs col-12 md:col-10 col-offset-0 md:col-offset-2 pt-0 mt-0 pl-5 mb-3"
      >
        <li v-for="(error, index) of v$.template.type.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("templates.type")) }}
        </li>
      </ul>
    </div>
    <h5>
      {{ $t("templates.uploadTemplate") }} ({{ $t("global.fileSmaller") }})
    </h5>
    <div class="grid grid-nogutter vertical-container">
      <div class="col">
        <FileUpload
          name="htmlTemplate"
          :showCancelButton="false"
          :customUpload="true"
          @uploader="uploadTemplate"
          :multiple="false"
          :maxFileSize="1000000"
          :invalidFileSizeMessage="`{0}: ${$t('global.invalidFileSize')} {1}.`"
          :invalidFileTypeMessage="`{0}: ${$t('global.invalidFileType')} {1}.`"
          @select="checkFiles"
          @remove="checkFiles"
          :chooseLabel="$t('global.choose')"
          :uploadLabel="$t('global.upload')"
        >
          <template #empty>
            <p>{{ $t("global.dragAndDrop") }} *</p>
          </template>
        </FileUpload>
      </div>
    </div>
    <div class="grid mt-1">
      <div class="col">
        <span>* {{ $t("global.mandatoryFields") }}</span>
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('global.create')"
        class="p-button-success"
        :disabled="!hasFile"
        @click="create"
        autofocus
        iconPos="right"
        :loading="loadingButton"
      />
      <Button
        :label="$t('global.cancel')"
        @click="close"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import { required } from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
export default {
  name: "Create edit template",
  props: ["display", "dataValue"],
  emits: ["closeCreateEditTemplate"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      submitted: false,
      loadingButton: false,
      buttonElement: "",
      hasFile: false,
      template: {
        name: "",
        type: "",
        file: "",
      },
      typeOptions: [
        { type: "application", name: this.$t("templates.application") },
        { type: "contract", name: this.$t("templates.contract") },
        {
          type: "certification-el",
          name: this.$t("templates.certificationEl"),
        },
        {
          type: "certification-en",
          name: this.$t("templates.certificationEn"),
        },
        {
          type: "certification-zh",
          name: this.$t("templates.certificationZh"),
        },
      ],
    };
  },
  validations() {
    return {
      template: {
        name: {
          required,
        },
        type: {
          required,
        },
      },
    };
  },
  watch: {
    async display(val) {
      this.viewDialog = val;
      console.log(this.data);
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        this.$nextTick(() => {
          this.buttonElement = document.querySelectorAll(
            ".p-fileupload-buttonbar button"
          )[1];
          this.buttonElement.style.display = "none";
        });
        this.template = Object.assign({}, this.data);
      }
    },
  },
  computed: {
    data() {
      return {
        ...this.dataValue,
      };
    },
  },
  methods: {
    checkFiles(event) {
      if (event.files.length > 0) {
        this.hasFile = true;
      } else {
        this.hasFile = false;
      }
    },
    async uploadTemplate(event) {
      this.loadingButton = true;

      this.template.file = event.files[0];
      await this.$store
        .dispatch("uploadTemplate", this.template)
        .then((res) => {
          this.$action("template-create", { templateId: res.id });
        });
      this.loadingButton = false;
      this.$notification(
        this.$t("templates.uploadTemplate"),
        this.$t("templates.templateUpload")
      );
      this.close(true);
    },
    async create() {
      this.submitted = true;
      const result = await this.v$.$validate();
      if (result) {
        this.buttonElement.click();
      }
    },
    close(success = false) {
      this.template = {};
      this.submitted = false;
      this.$emit("closeCreateEditTemplate", success);
    },
  },
};
</script>
