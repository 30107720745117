<template>
  <CreateEditTemplate
    :dataValue="selectedTemplate"
    :display="displayTemplate"
    @closeCreateEditTemplate="closeTemplate"
  >
  </CreateEditTemplate>
  <FileUpload
    hidden
    name="htmlTemplate"
    :showCancelButton="false"
    :customUpload="true"
    @uploader="uploadTemplate"
    :multiple="false"
    :maxFileSize="1000000"
    @select="upload"
    :chooseLabel="$t('global.choose')"
    :uploadLabel="$t('global.upload')"
  >
  </FileUpload>
  <Card>
    <template #title>
      <div class="grid">
        <div class="col-3 md:col-4">
          <h4>{{ $t("templates.title") }}</h4>
        </div>
        <div class="col-5 md:col-6 text-right">
          <Button
            v-if="!isMobile"
            :label="$t('global.export')"
            @click="exportCSV"
          ></Button>
        </div>
        <div class="col-4 md:col-2 text-right">
          <Button :label="$t('templates.addTemplate')" @click="showTemplate" />
        </div>
      </div>
    </template>
    <template #content v-if="loading || templates.length > 0">
      <DataTable
        :value="templates"
        :paginator="true"
        :rows="10"
        :loading="loading"
        :filters="filters"
        ref="tableTemplates"
        dataKey="id"
        showGridlines
        class="p-datatable-templates"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="$tableString"
        removableSort
        sortField="createdDate"
        :sortOrder="-1"
      >
        <template #header>
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('global.search')"
              />
            </span>
          </div>
        </template>
        <template #empty> {{ $t("templates.noTemplatesFound") }} </template>
        <Column
          field="id"
          :header="$t('templates.templateId')"
          :sortable="true"
        >
          <template #body="{ data }">
            <div class="text-right md:text-left">
              {{ data.id }}
            </div>
          </template>
        </Column>
        <Column
          field="tempCreatedAt"
          :header="$t('templates.dateCreated')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.createdAt }}
          </template>
        </Column>

        <Column
          field="type"
          :header="$t('templates.fileType')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ translateType(data.type) }}
          </template>
        </Column>
        <Column field="name" :header="$t('files.fileName')" :sortable="true">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>
        <Column
          field="Actions"
          :header="$t('global.actions')"
          :exportable="false"
        >
          <template #body="{ data, index }">
            <div class="flex justify-content-evenly">
              <Button
                class="p-button-text mdi mdi-24px"
                @click="updateTemplate(data, index)"
                :class="
                  loadingUpload && uploadIndex == index ? '' : 'mdi-upload'
                "
                :loading="loadingUpload && uploadIndex == index"
              />
              <Button
                class="p-button-text mdi mdi-24px mdi-download"
                @click="downloadTemplate(data)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #content v-else-if="!loading">
      <NoTableData :type="'templates'"></NoTableData>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";
import CreateEditTemplate from "./CreateEditTemplate.vue";
import NoTableData from "../NoTableData.vue";

export default {
  name: "Teplates",
  components: {
    CreateEditTemplate,
    NoTableData,
  },
  data() {
    return {
      loading: false,
      selectedTemplate: {},
      displayTemplate: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      buttonElementUpload: "",
      uploadIndex: null,
      buttonElementSelect: "",
      loadingUpload: false,
    };
  },
  computed: {
    ...mapGetters(["templates", "isMobile"]),
  },
  async mounted() {
    await this.getTemplates();

    this.buttonElementUpload = document.querySelectorAll(
      ".p-fileupload-buttonbar button"
    )[0];
    this.buttonElementSelect = document.querySelectorAll(
      ".p-fileupload-choose"
    )[0];
  },
  methods: {
    upload() {
      this.$nextTick(() => {
        this.buttonElementUpload.click();
      });
    },
    async uploadTemplate(event) {
      this.loadingUpload = true;
      this.selectedTemplate.file = event.files[0];
      await this.$store
        .dispatch("updateTemplate", this.selectedTemplate)
        .then(() => {
          this.$action("template-update", {
            templateId: this.selectedTemplate.id,
          });
        });
      this.loadingUpload = false;
      this.uploadIndex = null;
      this.$notification(
        this.$t("templates.uploadTemplate"),
        this.$t("templates.templateUpload")
      );
      await this.getTemplates();
    },
    exportCSV() {
      this.$refs.tableTemplates.exportCSV();
      this.$action("export-templates");
    },
    async getTemplates() {
      this.loading = true;
      await this.$store.dispatch("templates");
      this.loading = false;
    },
    showTemplate(data) {
      this.selectedTemplate = data;
      this.displayTemplate = true;
    },
    updateTemplate(data, index) {
      this.selectedTemplate = data;
      this.uploadIndex = index;
      this.buttonElementSelect.click();
    },
    downloadTemplate(template) {
      this.$store.dispatch("downloadTemplate", template.id);
      this.$action("template-download");
    },
    async closeTemplate(value) {
      this.selectedTemplate = {};
      this.displayTemplate = false;
      if (value) {
        await this.getTemplates();
      }
    },
    translateType(type) {
      switch (type) {
        case "application":
          return this.$t("templates.application");
        case "contract":
          return this.$t("templates.contract");
        case "certification-el":
          return this.$t("templates.certificationEl");
        case "certification-en":
          return this.$t("templates.certificationEn");
        case "certification-zh":
          return this.$t("templates.certificationZh");
      }
    },
  },
};
</script>
