<template>
  <div
    class="flex flex-column align-items-center justify-content-center h-20rem border-solid border-200 mb-5"
  >
    <div class="flex align-items-center justify-content-center h-auto">
      <span class="mdi mdi-96px mdi-orange mdi-folder-alert-outline"></span>
    </div>
    <div class="flex align-items-center justify-content-center mb-3">
      <b>
        {{ title }}
      </b>
    </div>
    <div class="flex align-items-center justify-content-center text-center">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: "No table data",
  props: ["type"],
  data() {
    return {
      title: "",
      subtitle: "",
    };
  },
  mounted() {
    switch (this.type) {
      case "application":
        this.title = this.$t("applications.noApplicationsYet");
        this.subtitle = this.$t("applications.youWillBeNotified");
        break;
      case "contracts":
        this.title = this.$t("contracts.noContractsYet");
        this.subtitle = this.$t("contracts.youWillBeNotified");
        break;
      case "certifications":
        this.title = this.$t("certifications.noCertificationsYet");
        this.subtitle = this.$t("certifications.youWillBeNotified");
        break;
      case "cases":
        this.title = this.$t("cases.noCasesYet");
        this.subtitle = this.$t("cases.youWillBeNotified");
        break;
      case "files":
        this.title = this.$t("files.noFilesYet");
        this.subtitle = this.$t("files.youWillBeNotified");
        break;
      case "templates":
        this.title = this.$t("templates.noTemplatesYet");
        this.subtitle = this.$t("templates.youWillBeNotified");
        break;
      case "renewals":
        this.title = this.$t("renewals.noRenewalsYet");
        this.subtitle = this.$t("renewals.youWillBeNotified");
        break;
      case "renderFile":
        this.title = this.$t("global.failRenderFile");
        this.subtitle = this.$t("global.contactAdmin")
        break;
      case "ratings":
        this.title = this.$t("ratings.noRatingsYet");
        this.subtitle = this.$t("ratings.youWillBeNotified")
        break;
      case "complaints":
        this.title = this.$t("complaints.noComplaintsYet");
        this.subtitle = this.$t("complaints.youWillBeNotified")
        break;
      case "invoices":
        this.title = this.$t("invoices.noInvoicesYet");
        this.subtitle = this.$t("invoices.youWillBeNotified")
        break;
      default:
        break;
    }
  },
};
</script>
